@import "../Font/font.scss";
@import "./Variables.scss";
@import "./_input.scss";
@import "./responsive.scss";

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0 !important;
}

body {
  font-family: "Lato", sans-serif;
  box-sizing: border-box;
}

.cursor-pointer {
  cursor: pointer;
}

.mt-6 {
  margin-top: 4.5rem;
}

.back_btn button {
  position: fixed;
  top: 20px;
  left: 10px;
  min-width: auto !important;
  background-color: transparent !important;
}

.custom_table {
  min-height: 57vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

table {
  tbody {
    border-bottom-width: 0 !important;
    text-wrap: nowrap;

    tr {
      cursor: pointer;

      td {
        vertical-align: middle;
        font-size: var(--p);
        font-weight: 600;
        padding: 0.6rem 0.5rem;
        border-bottom-width: 0 !important;
        color: var(--black) !important;

        small {
          font-weight: 400;
        }

        &:first-child {
          text-align: left;
          padding-left: 1.5rem;
          height: 68px;
        }
      }
    }
  }

  thead {
    border-bottom-width: 0 !important;

    tr {
      th {
        background-color: var(--outlet-bg) !important;
        text-wrap: nowrap;
        border-bottom-width: 0 !important;
        color: var(--gray4) !important;
        font-size: var(--p0);
        padding: 0.8rem 0.5rem !important;

        &:first-child {
          border-top-left-radius: 12px;
          padding-left: 1.5rem !important;
        }

        &:last-child {
          border-top-right-radius: 12px;
        }
      }
    }
  }
}

.date-picker {
  row-gap: 1rem;

  label {
    margin-bottom: 0.5rem;
    color: var(--light-text);
    font-size: var(--p);
    font-weight: 400;
    line-height: 21px;
  }

  .input-group-text {
    padding-left: 0;
  }

  .react-datepicker-popper {
    z-index: 9;
    line-height: 0;
  }

  .input-group {
    flex-wrap: nowrap;

    .react-datepicker-wrapper {
      width: 100%;

      input {
        border: none;
        padding: 0.575rem 0;
        font-size: var(--p);
        font-weight: 700;
        color: var(--light-text);
        min-width: 100px;
        width: 100%;
        border-bottom: 1px solid var(--border-light);

        &::placeholder {
          color: var(--placeholder);
          font-size: var(--p);
        }

        &:focus {
          box-shadow: none;
          outline: none;
        }
      }
    }
  }
}

.hand-hover {
  cursor: pointer;
}

.dropdown {
  padding: 0;
  border: none !important;

  &:focus {
    outline: none;
    border: 0;
    background-color: transparent !important;
    border-color: transparent !important;
  }

  .dropdown-toggle {
    font-weight: 700;
    color: var(--light-text);
    padding: 0.5rem 0 !important;
    background-color: transparent !important;
    border-color: transparent !important;

    &:hover {
      color: var(--black4);
    }

    &::after {
      display: none;
    }
  }
}

.status {
  padding: 0.4rem 0.5rem;
  border-radius: 8px;
  width: fit-content;
  margin: 0 auto;

  p {
    text-transform: capitalize;
  }

  &__primary {
    background-color: var(--list-hover);
    color: var(--primary);
  }

  &__secondary {
    background-color: var(--light-yellow);
    color: var(--yellow);
  }

  &__success {
    background-color: var(--success-bg);
    color: var(--success);
  }

  &__fail {
    background-color: var(--fail-bg);
    color: var(--fail);
  }
}

.patients_ui {
  .basic_details {
    p {
      font-size: var(--p);
    }

    .image_section {
      display: flex;
      align-items: start;
      gap: 2rem;
      margin-top: 0.5rem;

      .profile_pic {
        width: 149px;
        min-width: 149px;
        height: 139px;
        border: 1px solid var(--light-text);
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden;

        .image_box {
          width: 100%;
          height: 78%;

          video,
          img {
            object-fit: cover;
            height: 100%;
            width: 100%;
          }
        }

        .image_buttons {
          display: flex;
          align-items: center;
          border-top: 1px solid var(--light-text);
          justify-content: space-between;
          padding: 0 0.6rem;
          min-height: 30px;

          button {
            width: 100%;

            height: 100%;
          }

          #border-left {
            border-left: 1px solid var(--light-text);
            border-radius: 0 !important;
            padding-left: 1rem !important;
          }
        }
      }

      .notes {
        padding: 1rem;
        border-bottom: var(--outlet-bg);
        width: 100%;

        p {
          color: var(--light-text);
        }
      }
    }
  }

  .prescription_list {
    p {
      font-size: var(--p);
    }

    a {
      text-decoration: none;
      color: var(--primary);
      font-size: var(--p);
    }
  }
}

.patient_list {
  padding: 1rem 0 !important;

  .top_bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.5rem 1.5rem 1.5rem;
    flex-wrap: wrap;
    row-gap: 1rem;

    h2 {
      font-weight: 700;
      font-size: var(--h5);
    }

    .search_filter {
      row-gap: 1rem;

      .bg_grey {
        background-color: var(--outlet-bg);
        border-radius: 8px;
      }

      .input-group {
        margin-bottom: 0 !important;
        background-color: var(--outlet-bg);
        border-radius: 8px;
        padding-inline: 0.7rem;

        .input-group-text {
          border-bottom: 0;
          cursor: pointer;
        }

        input {
          background-color: var(--outlet-bg);
          border-bottom: 0;
        }
      }
    }
  }

  .table {
    thead tr th:first-child {
      text-align: start;
      padding-left: 1.5rem !important;
    }

    tbody {
      overflow-y: auto;
      max-height: 50vh;
    }

    .address {
      text-align: center;

      p {
        font-size: var(--p);
      }
    }

    .patient_name {
      display: flex;
      align-items: center;
      gap: 1rem;
      padding-left: 1rem;

      .profile_picture {
        width: 52px;
        height: 52px;
        overflow: hidden;
        border-radius: 8px;

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }

      .name_id {
        text-align: start;

        p {
          font-size: var(--p);
        }

        small {
          font-weight: 400;
        }
      }
    }
  }

  .no_data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;
    width: 100%;
    min-height: 55vh;

    h2 {
      font-size: var(--h3);
    }
  }
}

.card-title {
  margin-bottom: 0.8rem;
  font-weight: 700;
  font-size: var(--h6);
}

.required {
  position: relative;

  &::after {
    content: "*";
    display: inline;
    color: red;
    margin-left: 0.25em;
  }
}

.form-label {
  width: 100%;
  text-align: left;
}

// .small_select{
//   .select__menu{
//     max-height: 200px !important;
//     overflow-y: scroll !important;
//     .select__menu-list{
//       overflow-y: scroll !important;
//     }
//   }
// }

.template-box {
  border-radius: 12px !important;

  &:hover {
    border: 1px solid red !important;
    border-radius: 12px !important;
    box-shadow: 1px 1px 20px rgba(238, 135, 135, 0.713);
  }
}

.select__option {
  text-align: left;
}