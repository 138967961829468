

/* Extra small devices (phones, 576px and down) */
@media (max-width: 576px) {
    .main_outlet {
        padding: 1rem !important;
    }
    .date-picker .input-group .react-datepicker-wrapper input{
        min-width: auto !important;
    }
    .home .main_outlet .nav_bar{
        top: -20px !important;
    }
}

/* Small devices (tablets, 768px and down) */
@media (max-width: 768px) {
    .home .main_outlet .nav_bar{
        gap: 8px;
        flex-wrap: wrap;
    }
}

/* Medium devices (desktops, 992px and down) */
@media (max-width: 992px) {
    
}

/* Large devices (large desktops, 1200px and down) */
@media (max-width: 1200px) {
    
}

/* Extra large devices (extra large desktops, 1400px and down) */
@media (max-width: 1400px) {
    
}
