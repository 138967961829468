.bg_primary {
  background-color: var(--primary);
}
.vh100 {
  height: 100vh;
}
.rounded_right {
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}
.side_image img {
  object-fit: contain;
}

.login_form {
  max-width: 370px;
  text-align: left;
  padding: 2rem 0;
  min-width: 400px;
  .btn {
    min-width: 178px;
  }
  .login_header {
    font-size: var(--h1);
    font-weight: 700;
    line-height: 42px;
  }
  .login_sub_heading,
  label {
    color: var(--light-text);
    font-size: var(--p);
    font-weight: 400;
    line-height: 21px;
  }
  .form-control {
    padding: 0.575rem 0rem;
    font-size: var(--p);
    font-weight: 600;
    line-height: 1.5;
    border: none;
    border-bottom: 1px solid var(--border-light);
    border-radius: 0;
    color: var(--black3);
  }
  .input-group-text {
    border: none;
    background-color: transparent;
    border-bottom: 1px solid var(--border-light);
    border-radius: 0;
  }
  .form-control:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: none;
  }
  .login_link {
    font-size: var(--p);
    text-decoration: none;
    color: var(--primary);
  }
}
@media screen and (max-width: 768px) {
  .vh100 {
    height: auto;
  }
  .rounded_right {
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    border-bottom-right-radius: 0 !important;
  }
  .side_image {
    img {
      max-height: 300px;
      width: 100%;
      object-fit: cover;
    }
  }
}
@media only screen and (max-width:"467px") {
  .login_form{
    min-width: auto !important;
  }
  
}